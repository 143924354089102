import React from "react";
import BottomNavbar from "../components/common/bottomNavbar/bottomNavbar";
import AboutUs from "../components/about/aboutus";
import { Helmet } from "react-helmet";
import Banner from "../components/common/banner/banner";
import { useTranslation } from "react-i18next";
import QualityPolicy from "../components/about/qualityPolicy";
import AboutSidebar from "../components/about/aboutSidebar";
import AboutBody from "../components/about/aboutBody";

export default function Quality() {
  const { t } = useTranslation();
  return (
    <div>
      <Helmet>
        <title></title>
      </Helmet>
      <Banner
        urlBanner="/DugongoAboutBg.jpg"
        titleBanner={t("about:body.6.title")}
      />
      <BottomNavbar
        currentPage={t("navbar.dropdowns.14")}
        mainPage={t("navbar.links.2")}
        mainUrl="/Group-Profile"
      />

      <div className="containter-fluid about-us--container">
        <div className="container-lg px-3 about-us">
          <AboutSidebar
            title={t("about:qualityPolicy.title3")}
            subTitle={t("about:qualityPolicy.title4")}
          />

          <QualityPolicy />
        </div>
      </div>
    </div>
  );
}
