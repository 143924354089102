import Banner from "../components/common/banner/banner";
import BottomNavbar from './../components/common/bottomNavbar/bottomNavbar';
import { useTranslation } from "react-i18next";
import ProductCenter from './../components/product/productCener';
import { Helmet } from "react-helmet";

export default function PrefabSquareCulvert() {
    const { t } = useTranslation()
     
    return (
        <div>
            <Helmet>
                <title>{t('title.prefab')}</title>
            </Helmet>
           <Banner 
           urlBanner="/CulvertBg.jpg"
           titleBanner={t('banner.prefab')}
            />
             <BottomNavbar
                currentPage={t('navbar.dropdowns.10')}
                mainPage={t('navbar.links.3')}
                mainUrl="/Marine-Cement"
            />
             <ProductCenter
                current={4}
            />
        </div>
    )
}