import Banner from "../components/common/banner/banner";
import BottomNavbar from './../components/common/bottomNavbar/bottomNavbar';
import { useTranslation } from "react-i18next";
import CareerComp from "../components/hr/careerComp";
import { Helmet } from "react-helmet";

export default function Career() {
    const { t} = useTranslation()
    return (
        <div>
            <Helmet>
                <title>{t('title.career')}</title>
            </Helmet>
           <Banner 
           urlBanner="/CareerImg2.jpg"
           titleBanner={t('banner.career')}
           />
             <BottomNavbar
                currentPage={t('navbar.dropdowns.13')}
                mainPage={t('navbar.links.4')}
                mainUrl="/Talent-Strategy"
            />
            <CareerComp/>
        </div>
    )
}