import "./aboutus.scss";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

export default function AboutBody(props) {
  const { t } = useTranslation();
  return (
    <div className="about-body-container">
      <Fade bottom cascade distance="15%">
        <div className="about-body">
          <p className="about-body-text">{props.text1}</p>
          <p className="about-body-text">{props.text2}</p>
          <p className="about-body-text">{props.text3}</p>
          <div className="about-body-img">
            <img src={props.imgUrl} alt={props.imgAlt} />
          </div>
        </div>
      </Fade>
    </div>
  );
}
