import { AiFillHome } from "react-icons/ai";
import { RxTriangleRight } from "react-icons/rx";
import "./bottomNavbar.scss";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

export default function BottomNavbar(props) {
  const { t } = useTranslation();

  return (
    <div className="container-fluid bNav">
      <div className="container-lg px-3">
        <div className="bNav-container">
          <div className="bNav--links">
            <div className="bNav--link">
              <NavLink to="/">
                <span className="bNav-home--icon">
                  <AiFillHome />
                </span>
                <span>{t("navbar.links.1")}</span>
              </NavLink>
            </div>
            <div className="bNav--link ">
              <NavLink to={props.mainUrl}>
                <span className="bNav-triangle--icon">
                  <RxTriangleRight />
                </span>
                <span>{props.mainPage}</span>
              </NavLink>
            </div>
            <div className="bNav--link bNav-link--active">
              <span className="bNav-triangle--icon">
                <RxTriangleRight />
              </span>
              <span>{props.currentPage}</span>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
