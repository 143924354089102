import Banner from "../components/common/banner/banner";
import BottomNavbar from './../components/common/bottomNavbar/bottomNavbar';
import { useTranslation } from "react-i18next";
import Hr from "../components/hr/hr";
import { Helmet } from "react-helmet";


export default function TalentStrategy() {
    const { t } = useTranslation()

    return (
        <div>
            <Helmet>
                <title>{t('title.talent')}</title>
            </Helmet>
           <Banner 
                urlBanner="/HR1920x1280.jpg"
                titleBanner={t('banner.talent')}
            />
            <BottomNavbar
                currentPage={t('navbar.dropdowns.12')}
                mainPage={t('navbar.links.4')}
                mainUrl="/Talent-Strategy"
            />
            <Hr/>
        </div>
    )
}