import Banner from "../components/common/banner/banner";
import BottomNavbar from "./../components/common/bottomNavbar/bottomNavbar";
import { useTranslation } from "react-i18next";
import AboutUs from './../components/about/aboutus';
import { Helmet } from "react-helmet";

export default function ManateeProfile() {
  const { t } = useTranslation();
  return (
    <div>
      <Helmet>
        <title>{t('title.dugongo')}</title>
      </Helmet>
      <Banner urlBanner="/DugongoAboutBg.jpg" titleBanner={t('about:body.2.title')} />
      <BottomNavbar
        currentPage={t("navbar.dropdowns.2")}
        mainPage={t("navbar.links.2")}
        mainUrl="/Marine-Cement"
      />
      <AboutUs
        title={t("about:sidebar.about.sub2.1")}
        title2={t("about:body.2.title")}
        subTitle={t("about:sidebar.about.sub2.2")}
        text1={t("about:manateeProfile.text1")}
        text2={t("about:manateeProfile.text2")}
        text3={t("about:manateeProfile.text3")}
        imgUrl="about-mantee.png"
        imgAlt="manatee overview"
      />
    </div>
  );
}
