import Navbar from "./components/common/navbar/navbar";
import Home from "./routes/home";
import GroupProfile from "./routes/GroupProfile";
import ManateeProfile from "./routes/ManateeProfile";
import RnDCenter from "./routes/RnDCenter";
import CorporateCulture from "./routes/CorporateCulture";
import OrganizationalStructure from "./routes/OrganizationalStructure";
import MarineCement from "./routes/MarineCement";
import Quality from "./routes/Quality";
import OilWellCement from "./routes/OilWellCement";
import CementBrick from "./routes/CementBrick";
import ConcretePole from "./routes/ConcretePole";
import PrefabSquareCulvert from "./routes/PrefabSquareCulvert";
import ReinforcedConcretePipe from "./routes/ReinforcedConcretePipe";
import TalentStrategy from "./routes/TalentStrategy";
import Career from "./routes/Career";
import Contact from "./routes/contact";
import { Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Footer from "./components/common/footer/footer";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import News from "./routes/News";
import News001 from './routes/newsDetail/news001';
import News002 from './routes/newsDetail/news002';
import News003 from './routes/newsDetail/news003';
import News004 from './routes/newsDetail/news004';

function App() {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div id={i18n.resolvedLanguage === "cn" ? "cn-lang" : "pt-lang"}>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Group-Profile" element={<GroupProfile />} />
        <Route path="/Manatee-Profile" element={<ManateeProfile />} />
        <Route path="/R&D-Center" element={<RnDCenter />} />
        <Route path="/Corporate-Culture" element={<CorporateCulture />} />
        <Route path="/Quality" element={<Quality />} />
        <Route
          path="/Organizational-Structure"
          element={<OrganizationalStructure />}
        />
        <Route path="/Marine-Cement" element={<MarineCement />} />
        <Route path="/Oil-Well-Cement" element={<OilWellCement />} />
        <Route path="/Cement-Brick" element={<CementBrick />} />
        <Route path="/Concrete-Pole" element={<ConcretePole />} />
        <Route
          path="/Prefabricated-Square-Culvert"
          element={<PrefabSquareCulvert />}
        />
        <Route
          path="/Reinforced-Concrete-Pipe"
          element={<ReinforcedConcretePipe />}
        />
        <Route path="/Talent-Strategy" element={<TalentStrategy />} />
        <Route path="/News001" element={<News001 />} />
        <Route path="/News002" element={<News002 />} />
        <Route path="/News003" element={<News003 />} />
        <Route path="/News004" element={<News004 />} />
        <Route path="/Career" element={<Career />} />
        <Route path="/Contact" element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
