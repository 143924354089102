import React from "react";
import { useTranslation } from "react-i18next";
import img1 from "../../images/DugongoImg.png"

export default function QualityPolicy() {
  const { t } = useTranslation();
  return (
    <div className="about-body-container">
      <div className="about-body">
        <div className="quality-objective">
          <h3>{t("about:qualityObjective.title")}</h3>
          <p className="about-body-text">{t("about:qualityObjective.text1")}</p>
          <ol className="about-body-list-wrapper">
            <li>{t("about:qualityObjective.list6")}</li>
            <li> {t("about:qualityObjective.list7")}</li>
            <li>{t("about:qualityObjective.list8")}</li>
          </ol>
          <p className="about-body-text">{t("about:qualityObjective.text2")}</p>
              </div>
              <div className="about-body-img">
            {/* <img src={img1} alt="quality" /> */}
          </div>
        <div className="quality-policy">
          <h3>{t("about:qualityPolicy.title1")}</h3>
        <p className="about-body-text">{t("about:qualityPolicy.text1")}</p>
        <h4>{t("about:qualityPolicy.title2")}</h4>          
          <ol className="about-body-list-wrapper">
            <li>{t("about:qualityPolicy.list1")}</li>
            <li> {t("about:qualityPolicy.list2")}</li>
            <li>{t("about:qualityPolicy.list3")}</li>
            <li>{t("about:qualityPolicy.list4")} </li>
            <li>{t("about:qualityPolicy.list5")}</li>
          </ol>
        </div>
      </div>
    </div>
  );
}
