import "./NewsHome.scss";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Fade from "react-reveal/Fade";

export default function NewsHome() {
  const { t } = useTranslation();

  const news = [
    {
      img: "news001-sm-img1.jpg",
      headline: t("news:title.1"),
      date: "2021-03-31",
      text: t("news:shorts.1"),
      link: "/News001"
    },
    {
      img: "news002-sm-img1.jpg",
      headline: t("news:title.2"),
      date: "2021-03-31",
      text: t("news:shorts.2"),
      link: "/News002"
    },
    {
      img: "news003-sm-img1.jpg",
      headline: t("news:title.3"),
      date: "2021-03-30",
      text: t("news:shorts.3"),
      link: "/News003"
    },
    {
      img: "news004-sm-img1.png",
      headline: t("news:title.4"),
      date: "2021-03-30",
      text: t("news:shorts.4"),
      link: "/News004"
    }
  ];

  const len = news.length - 1;
  const [activeIdx, setActiveIdx] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIdx(activeIdx === len ? 0 : activeIdx + 1);
    }, 4500);
    return () => clearInterval(interval);
  }, [activeIdx]);

  return (
    <div>
      {news.map((data, idx) => {
        return (
          <NavLink
            to={data.link}
            className={idx === activeIdx ? "show-news" : "hide-news"}
          >
            <div className="news-home">
              <div className="img-wrapper">
                <img className="img" src={data.img} alt="news" />
              </div>

              <div className="content-wrapper">
                <div className="content">
                  <div className="headline">{data.headline}</div>
                  <div className="date">{data.date}</div>
                  <div className="text">{data.text}</div>
                </div>
                <div className="bullets">
                  {news.map((data, idx) => {
                    return (
                      <span
                        onClick={() => setActiveIdx(idx)}
                        className={
                          activeIdx === idx ? "bullet bullet-active" : "bullet"
                        }
                      ></span>
                    );
                  })}
                </div>
              </div>
            </div>
          </NavLink>
        );
      })}
    </div>
  );
}
