import React, { useState, useEffect } from "react";
import Pagination from "./Pagination";
import "./BannerHome.scss";
import { NavLink } from "react-router-dom";
import { FaThinkPeaks, FaConnectdevelop } from "react-icons/fa";
import { MdArrowRightAlt } from "react-icons/md";
import cementMarine from "../../../images/cementMarine.png";
import oilCement from "../../../images/oilCement.png";
import Bricks from "../../../images/Bricks.png";
import PipeImg from "../../../images/PipeImg.png";
import squareCulvert from "../../../images/squareCulvert.png";
import concretePole from "../../../images/concretePole.png";
import "./BannerHome.scss";
import HomeProdCardSm from "./HomeProdCardSm";
import { useTranslation } from "react-i18next";

function BannerHome() {
  const {t} = useTranslation()
  const sliderData = [
    {
      title: t('home:banner.1.title'),
      urls: "DugongoCompany.jpg",
    },
    {
      title: t('home:banner.2.title'),
      urls: "BridgeBg.jpg",
    },
    {
      title: t('home:banner.3.title'),
      urls: "BuildingBg.jpg",
    },
  ];
  const len = sliderData.length - 1;

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(activeIndex === len ? 0 : activeIndex + 1);
    }, 4000);
    return () => clearInterval(interval);
  }, [activeIndex]);

  return (
    <div>
      <section className="home-page  ">
        {sliderData.map((slide, index) => (
          <div
            key={index}
            // animationEffect="Fade"
            className={index === activeIndex ? "slides active" : "inactive"}
          >
            <div className=" slider-headline-body">
              <img className="slide-image" src={slide.urls} alt="" />
              <div className="banner-content container-lg px-3 d-flex  align-items-center">
                <div className="banner-headlines top-50 ">
                  <h1>{slide.title}</h1>
                  <h2 className="banner-headline-pt">{slide.title}</h2>
                </div>
              </div>
            </div>
            <Pagination
              prevSlide={() =>
                setActiveIndex(activeIndex < 1 ? len : activeIndex - 1)
              }
              nextSlide={() =>
                setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)
              }
            />
            <div className="home-prod-cards container-fluid px-0">
              <div className="prod-card-wrapper container-lg px-3 d-flex">
                <div className="home-promo-content d-flex flex-column  pe-4 align-items-start justify-content-center ">
                  <div className="prod-promo d-flex align-items-baseline ">
                    <h4 className="prod-promo-figure mb-0">5K</h4>
                    <span className="prod-promo-title ps-1">Ton</span>
                  </div>
                  <div className="d-flex align-items-baseline pt-1">
                    <div className="prod-promo-icon pe-2">
                      <FaThinkPeaks size={32} color="#FF9901" />
                    </div>
                    <span
                      className="prod-promo-title ps-1"
                      //style={{ fontWeight: "500" }}
                    >
                      {" "}
                      {t('home:unit.1')}
                    </span>
                  </div>
                </div>
                <div className="prod-card-container  d-flex justify-content-end align-items-end container-fluid px-0">
                  <div
                    className="prod-cards-promo card-color-1  me-1 d-flex flex-column justify-content-between "
                    style={{ backgroundColor: "#194189" }}
                  >
                    <NavLink to="/Marine-Cement">
                      <div>
                        <div
                          className="p-2"
                          style={{ backgroundColor: "#194189" }}
                        >
                          <div className="prod-card-hover">
                            <img src={cementMarine} alt="" />
                          </div>
                        </div>
                        <div className="p-2">
                          <h3 className=" card-title eng mb-1  ">
                            {t('home:card.1.title1')}
                          </h3>
                          <h3 className=" card-title  cn mb-0">{t('home:card.1.title2')}</h3>
                          <MdArrowRightAlt
                            className="prod-card-arrow"
                            size={24}
                            color="white"
                          />
                        </div>
                      </div>
                    </NavLink>
                  </div>

                  <div
                    className="prod-cards-promo card-color-1  me-1  d-flex flex-column  justify-content-between "
                    style={{ backgroundColor: "#468C98" }}
                  >
                    <NavLink to="/Oil-Well-Cement">
                      <div>
                        <div
                          className="p-2"
                          style={{ backgroundColor: "#468C98" }}
                        >
                          <div className="prod-card-hover">
                            <img src={oilCement} alt="" />
                          </div>
                        </div>
                        <div className="p-2">
                          <h3 className=" card-title eng mb-1 ">
                          {t('home:card.2.title1')}
                          </h3>
                          <h3 className=" card-title  cn mb-0">{t('home:card.2.title2')}</h3>
                          <MdArrowRightAlt
                            className="prod-card-arrow"
                            size={24}
                            color="white"
                          />
                        </div>
                      </div>
                    </NavLink>
                  </div>
                  <div
                    className="prod-cards-promo card-color-1  me-1 d-flex flex-column  justify-content-between"
                    style={{ backgroundColor: "#B3A394" }}
                  >
                    <NavLink to="/Cement-Brick">
                      <div>
                        <div
                          className="p-2"
                          style={{ backgroundColor: "#B3A394" }}
                        >
                          <div className="prod-card-hover">
                            <img src={Bricks} alt="" />
                          </div>
                        </div>
                        <div className="p-2">
                          <h3 className=" card-title eng mb-1 ">
                          {t('home:card.3.title1')}
                          </h3>
                          <h3 className=" card-title  cn mb-0">{t('home:card.3.title2')}</h3>
                          <MdArrowRightAlt
                            className="prod-card-arrow"
                            size={24}
                            color="white"
                          />
                        </div>
                      </div>
                    </NavLink>
                  </div>
                  <div
                    className="prod-cards-promo card-color-1  me-1 d-flex flex-column  justify-content-between"
                    style={{ backgroundColor: "#F7934C" }}
                  >
                    <NavLink to="/Concrete-Pole">
                      <div>
                        <div
                          className="p-2 "
                          style={{ backgroundColor: "#F7934C" }}
                        >
                          <div className="prod-card-hover">
                            <img src={concretePole} alt="" />
                          </div>
                        </div>
                        <div className="p-2 ">
                          <h3 className=" card-title eng mb-1 ">
                          {t('home:card.4.title1')}
                          </h3>
                          <h3 className=" card-title  cn mb-0">{t('home:card.4.title2')}</h3>
                          <MdArrowRightAlt
                            className="prod-card-arrow"
                            size={24}
                            color="white"
                          />
                        </div>
                      </div>
                    </NavLink>
                  </div>
                  <div
                    className="prod-cards-promo card-color-1  me-1 d-flex flex-column  justify-content-between"
                    style={{ backgroundColor: "#048A81" }}
                  >
                    <NavLink to="/Prefabricated-Square-Culvert">
                      <div>
                        <div
                          className="p-2 "
                          style={{ backgroundColor: "#048A81" }}
                        >
                          <div className="prod-card-hover">
                            <img src={squareCulvert} alt="" />
                          </div>
                        </div>
                        <div className="p-2">
                          <h3 className=" card-title eng mb-1 ">
                          {t('home:card.5.title1')}
                          </h3>
                          <h3 className=" card-title  cn mb-0">{t('home:card.5.title2')}</h3>
                          <MdArrowRightAlt
                            className="prod-card-arrow"
                            size={24}
                            color="white"
                          />
                        </div>
                      </div>
                    </NavLink>
                  </div>
                  <div
                    className="prod-cards-promo card-color-1   d-flex flex-column  justify-content-between"
                    style={{ backgroundColor: "#AEB4A9" }}
                  >
                    <NavLink to="/Reinforced-Concrete-Pipe">
                      <div>
                        <div
                          className="p-2 "
                          style={{ backgroundColor: "#AEB4A9" }}
                        >
                          <div className="prod-card-hover">
                            <img src={PipeImg} alt="" />
                          </div>
                        </div>
                        <div className="p-2">
                          <h3 className=" card-title eng mb-1  ">
                          {t('home:card.6.title1')}
                          </h3>
                          <h3 className=" card-title  cn mb-0">{t('home:card.6.title2')}</h3>
                          <MdArrowRightAlt
                            className="prod-card-arrow"
                            size={24}
                            color="white"
                          />
                        </div>
                      </div>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="home-prod-card-small container-fluid">
          <HomeProdCardSm />
        </div>
      </section>
    </div>
  );
}

export default BannerHome;
{
  /* {cardContent.map((content, index) => {
                    //console.log(content);
                    return <ProdHomeCard  eng={content.eng} cn={content.cn} />;
                  })} */
}
