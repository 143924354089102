import "./NewsComp.scss"
import { NavLink } from "react-router-dom";
import SliderImage from "./SliderImage";
import PageNumber from "./PageNumber";
import Banner from "../common/banner/banner";
import BottomNavbar from "../common/bottomNavbar/bottomNavbar";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

export default function NewsComp(props) {
    const { t } = useTranslation()
    const location = useLocation()
    const news = [
        {
          headline: t('news:headline.1'),
          date: "2021-03-31",
          url: "news001-img1.jpg",
          link: "/News001"
        },
        {
          headline: t('news:headline.2'),
          date: "2021-03-31",
          url: "news002-img1.jpg",
          link: "/News002"
        },
        {
          headline: t('news:headline.3'),
          date: "2021-03-30",
          url: "news003-img1.jpg",
          link: "/News003"
        },
        {
            headline: t('news:headline.4'),
            date: "2021-03-30",
            url: "news004-img1.jpg",
            link: "/News004"
          }
      ];
    return (
        <div>
            <Helmet>
                <title>{ t('title.contact')}</title>
            </Helmet>
             <Banner 
           urlBanner="/ContactBG.jpg"
           titleBanner={t('banner.news')}
            />
             <BottomNavbar
                mainPage={t('navbar.links.6')}            
            />

            <div className="container-fluid news-comp-wrapper">
                <div className="container-lg news-comp">
                    <div className="news-comp-sidebar-wrapper">
                        <div className="news-comp-sidebar">
                            <div className="top-title">
                                <h2 className="h2">Latest News</h2>
                                <h3 className="h6">Latest News</h3>
                            </div>
                            <div className="news-list">
                                {news.map((data, idx) => {
                                    return (
                                        <NavLink to={data.link}>
                                            <div className={location.pathname === data.link ? "news-detail-card news-detail-card-active" : "news-detail-card"}>
                                                <img src={data.url} alt="" />
                                                <div className="content">
                                                    <div className="headline">{data.headline}</div>
                                                    <div className="date">{ data.date}</div>
                                                </div>
                                            </div>
                                        </NavLink>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="news-comp-content-wrapper">
                        <div className="news-comp-content">
                            <div className="headline">{props.headline}</div>
                            <div className="date">{props.date}</div>
                            <SliderImage
                                newsImage={props.newsImage}
                            />
                            {props.newsText.map((data, idx) => {
                                return <div className="text">{data}</div>
                            })}
                            <PageNumber />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}