import Banner from "../components/common/banner/banner";
import BottomNavbar from './../components/common/bottomNavbar/bottomNavbar';
import { useTranslation } from "react-i18next";
import ContactUs from "../components/contact/contactus";
import { Helmet } from "react-helmet";

export default function Contact() {
    const { t } = useTranslation()

    return (
        <div>
            <Helmet>
                <title>{ t('title.contact')}</title>
            </Helmet>
           <Banner 
           urlBanner="/ContactBG.jpg"
           titleBanner={t('banner.contact')}
            />
             <BottomNavbar
                mainPage={t('navbar.links.5')}            
            />
            <ContactUs />
        </div>
    )
}