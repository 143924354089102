import NewsComp from "../../components/news/NewsComp"
import { useTranslation } from "react-i18next"

export default function News003() {

    const { t } = useTranslation()
    
    const newsText = [
        t('news:news.3.p1'),
        t('news:news.3.p2'),
        t('news:news.3.p3'),
        t('news:news.3.p4')
    ]

    const newsImage = [
        "news003-img1.jpg"
    ]
    return (
        <div>
            <NewsComp
                newsImage={newsImage}
                newsText={newsText}
                headline={t('news:headline.3')}
                date="2021-03-30"
            />
        </div>
    )
}