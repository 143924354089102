import React from "react";
import "./banner.scss";
import Fade from "react-reveal/Fade";

const Banner = (props) => {
  return (
    <div>
      <div
        className="banner-common container-fluid  p-0 "
        style={{
          backgroundImage: "url(" + props.urlBanner + ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="banner-overlay"></div>
        <div className="baneer-wrap-hl ">
          <div className=" banner-title  container-fluid p-0">
            <Fade bottom  distance="25%" >
              <div className="banner-header container-lg px-3">
                <h1 className="banner-title-span ">{props.titleBanner}</h1>
              </div>
            </Fade>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Banner;
