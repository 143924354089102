import React from "react";
//import { Container } from "react-bootstrap";
import "./footer.scss";
import { BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import weChatQR from "../../../images/WeChatQR.png";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Footer = () => {
  const {t} = useTranslation();
  return (
    <div className=" container-fluid p-0 footer-container">
      <div className="container-fluid container-lg px-3  footer ">
        <div className="row  flex-sm-row justify-content-between py-3 gx-0">
    
            <div className="col px-0">
              <div>
                <ul className="footer-links d-flex  mb-2 ">
                  <li className="footer-link-item footer-item-1 pe-2  ">
                    <NavLink to="/" className="footer-link">{t(`common:Footer.footer-link1`)}</NavLink>
                  </li>
                  <li className="footer-link-item pe-2 ">
                    <NavLink to="/Group-Profile">{t(`common:Footer.footer-link2`)}</NavLink>
                  </li>
                  <li className="footer-link-item pe-2 ">
                    <NavLink to="/Marine-Cement">{t(`common:Footer.footer-link3`)}</NavLink>
                  </li>
                  <li className="footer-link-item pe-2 ">
                    <NavLink to="/Talent-Strategy">{t(`common:Footer.footer-link4`)}</NavLink>
                  </li>
                  <li className="footer-link-item  ">
                    <NavLink to="/Contact">{t(`common:Footer.footer-link5`)}</NavLink>
                  </li>
                </ul>
              </div>
             <span className="footer-copyright mb-0">
              {t(`common:Footer.copy-right`)}
              </span>
            </div>
          
          <div className="col d-none   d-lg-block px-0">
            <ul className="footer-contact  d-flex mb-0 ps-0  justify-content-between justify-content-lg-end   ">
              <li className="footer-phone pe-3 ">
                <BsTelephone size={16} /> {t(`common:Footer.footer-phone`)}
              </li>
              <li className="footer-email pe-3 ">
                <AiOutlineMail size={16} /> {t(`common:Footer.footer-email`)}
              </li>
              <li className="footer-wechat ">
                <div className="wechat-qr">
                  <img src={weChatQR} alt="" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
