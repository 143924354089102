import Banner from "../components/common/banner/banner";
import BottomNavbar from "./../components/common/bottomNavbar/bottomNavbar";
import { useTranslation } from "react-i18next";
import AboutUs from "../components/about/aboutus";
import { Helmet } from "react-helmet";

export default function RnDCenter() {
  const { t } = useTranslation(); 

  return (
    <div>
       <Helmet>
        <title>{t('title.rnd')}</title>
      </Helmet>
      <Banner urlBanner="/R&DImg.jpg" titleBanner={t('about:body.3.title')} />
      <BottomNavbar
        currentPage={t("navbar.dropdowns.3")}
        mainPage={t("navbar.links.2")}
        mainUrl="/R&D-Center"
      />
      <AboutUs
        title={t("about:sidebar.about.sub3.1")}
        title2={t("about:body.3.title")}
        subTitle={t("about:sidebar.about.sub3.2")}
        text1={t("about:RnDcenter.text1")}
        text2={t("about:RnDcenter.text2")}
        text3={t("about:RnDcenter.text3")}
        imgUrl="r-and-d.png"
        imgAlt="Research and Deisgn"
      />
    </div>
  );
}
