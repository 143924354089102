import NewsComp from "../../components/news/NewsComp"
import { useTranslation } from "react-i18next"

export default function News002() {
    const {t} = useTranslation()


    const newsText = [
        t('news:news.2.p1'),
        t('news:news.2.p2'),
        t('news:news.2.p3'),
        t('news:news.2.p4'),
        t('news:news.2.p5'),
        t('news:news.2.p6'),
        t('news:news.2.p7')
    ]

    const newsImage = [
        "news002-img1.jpg"
    ]
    return (
        <div>
            <NewsComp
                headline={t('news:headline.2')}
                date="2021-03-31"
                newsImage={newsImage}
                newsText={newsText}
            />
        </div>
    )
}