import NewsComp from "../../components/news/NewsComp"
import { useTranslation } from "react-i18next"

export default function News001() {

    const {t} = useTranslation()

    const newsText = [
        t('news:news.1.p1'),
        t('news:news.1.p2'),
        t('news:news.1.p3'),
        t('news:news.1.p4')
    ]

    const newsImage = [
        "news001-img1.jpg"
    ]
    return (
        <div>
            <NewsComp
                newsImage={newsImage}
                newsText={newsText}
                date="2021-03-31"
                headline={t('news:headline.1')}
            />
        </div>
    )
}