import React from "react";
import "./homeAbout.scss";
import { MdArrowRightAlt } from "react-icons/md";
import Dugongo from "../../../images/DugongoImg.png";
import { NavLink } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { useTranslation } from "react-i18next";
import NewsHome from "../newsHome/NewsHome";

const HomeAbout = () => {
  const { t } = useTranslation();
  return (
    <div className="home-abt-container">
      <div className="home-about container-fluid px-0 ">
        <div className="container-fluid container-lg px-3 d-lg-flex ">
          <Fade left cascade distance="15%">
            <div className="home-abt col d-flex flex-column-reverse flex-sm-row gy-3 gap-2 ">
              <div className="home-abt-img  ">
                <img src={Dugongo} alt="" />
              </div>

              <Fade left cascade distance="15%">
                <div className="home-abt-content">
                  <h3>{t("home:about.1.title")}</h3>
                  <p className="home-abt-text mb-0">{t("home:about.1.text")}</p>
                  <NavLink to="/Manatee-Profile">
                    <MdArrowRightAlt
                      className="home-abt-arrow"
                      color="#194189"
                      size={32}
                    />
                  </NavLink>
                </div>
              </Fade>
            </div>
          </Fade>
          <Fade left cascade distance="15%">
            <div className="home-contact col">
              {/* <h3 className="abt-header-eng ">{t("home:about.2.title1")}</h3>
              <h3>{t("home:about.2.title2")}</h3>
              <p className="home-contact-text" style={{ fontSize: "14px" }}>
                {t("home:about.2.text")}
              </p> */}
                <NewsHome />
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default HomeAbout;
