import Banner from "../components/common/banner/banner";
import BottomNavbar from "./../components/common/bottomNavbar/bottomNavbar";
import { useTranslation } from "react-i18next";
import AboutUs from "../components/about/aboutus";
import { Helmet } from "react-helmet";

export default function OrganizationalStructure() {
  const { t } = useTranslation();
  return (
    <div>
      <Helmet>
        <title>{ t('title.org')}</title>
      </Helmet>
      <Banner
        urlBanner="/DugongoAboutBg.jpg"
        titleBanner={t('about:body.5.title')}
      />
      <BottomNavbar
        currentPage={t("navbar.dropdowns.5")}
        mainPage={t("navbar.links.2")}
        mainUrl="/Group-Profile"
      />
      <AboutUs
        title={t("about:sidebar.about.sub5.1")}
        title2={t("about:body.5.title")}
        subTitle={t("about:sidebar.about.sub5.2")}
        text1={t("about:orgStructure.text1")}
        text2={t("about:orgStructure.text2")}
        text3={t("about:orgStructure.text3")}
        imgUrl="org-struct.png"
        imgAlt="Organization structure"
      />
    </div>
  );
}
