import "./contactus.scss";
import wechat from "../../images/WeChat.png";
import facebook from "../../images/facebook.png";
import youtube from "../../images/youtube.png";
import twitter from "../../images/twitter.png";
import { useTranslation } from "react-i18next";
import { Component } from "react";
import Fade from "react-reveal/Fade";
import { withTranslation } from "react-i18next";

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        name: "",
        email: "",
        message: "",
      },
      isSubmitting: false,
      isError: false,
    };
  }

  submitForm = async (e) => {
    e.preventDefault();
    console.log(this.state);
    this.setState({ isSubmitting: true });

    const res = await fetch(
      "https://yfe4aafl56.execute-api.eu-west-1.amazonaws.com/Prod/contact_dugongo",
      {
        method: "POST",
        body: JSON.stringify(this.state.values),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    this.setState({ isSubmitting: false });
    const data = await res.json();
    !data.hasOwnProperty("error")
      ? this.setState({ message: data.success })
      : this.setState({ message: data.error, isError: true });

    setTimeout(
      () =>
        this.setState({
          isError: false,
          message: "",
          values: { name: "", email: "", message: "" },
        }),
      1600
    );
  };

  handleInputChange = (e) =>
    this.setState({
      values: { ...this.state.values, [e.target.name]: e.target.value },
    });

  render() {
    const { t } = this.props;

    return (
      <div className="container-fluid contact-us-wrapper">
        <div className="container-lg contact-us">
          <div className="contact-us-sidebar-wrapper">
            <Fade cascade bottom distance="15%">
              <div className="contact-us-sidebar">
                <div className="contact-us-sidebar-top-title">
                  <h2 className="h2">{t("contact:sidebar.top.title1")}</h2>
                  <h3 className="h6">{t("contact:sidebar.top.title2")}</h3>
                </div>
                <div className="contact-us-sidebar-detail">
                  <div className="contact-phone">
                    <h3 className="h6">{t("contact:sidebar.detail.title1")}</h3>
                    <h4 className="h6">+258 21362691</h4>
                  </div>
                  <div className="contact-email">
                    <h3 className="h6">{t("contact:sidebar.detail.title2")}</h3>
                    <h4 className="h6">dgc-sales@westchinacement.cn</h4>
                  </div>
                  <div className="contact-location">
                    <h3 className="h6">{t("contact:sidebar.detail.title3")}</h3>
                    <h4 className="h6">
                      {t("contact:sidebar.detail.content3")}
                    </h4>
                  </div>
                </div>
                <div className="contact-us-sidebar-wechat">
                  <img src={wechat} alt="wechat QR code" />
                </div>
                <div className="contact-us-sidebar-footer">
                  <img src={twitter} alt="social icons" />
                  <img src={facebook} alt="social icons" />
                  <img src={youtube} alt="social icons" />
                </div>
              </div>
            </Fade>
          </div>
          <div className="contact-us-content-wrapper">
            <Fade cascade bottom distance="15%">
              <div className="contact-us-content">
                <div className="form-wrapper">
                  <div className="form-header">
                    <h3>{t("contact:content.header.title1")}</h3>
                    <h4>{t("contact:content.header.title2")}</h4>
                    <p>{t("contact:content.header.text")}</p>
                  </div>

                  {/* Form configuration */}

                  <form onSubmit={this.submitForm}>
                    <div>
                      <input
                        className="form-name"
                        name="name"
                        value={this.state.values.name}
                        onChange={this.handleInputChange}
                        type="text"
                        placeholder={t("contact:content.form.name")}
                      />
                      <input
                        className="form-email"
                        name="email"
                        type="email"
                        value={this.state.values.email}
                        onChange={this.handleInputChange}
                        placeholder={t("contact:content.form.email")}
                      />
                    </div>
                    <input
                      className="form-message"
                      name="message"
                      type="text"
                      value={this.state.values.message}
                      onChange={this.handleInputChange}
                      placeholder={t("contact:content.form.message")}
                    />
                    <button className="form-btn" type="submit">
                      {t("contact:content.form.btn")}
                    </button>
                  </form>
                  <div className={`message ${this.state.isError && "error"}`}>
                    {this.state.isSubmitting
                      ? "Submitting..."
                      : this.state.message}
                  </div>
                </div>
                <div className="map-wrapper">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7413.195928291583!2d32.64505135508766!3d-26.394612724062245!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ee43f62ded39b01%3A0xb27970df013db799!2sMo%C3%A7ambique%20Dugongo%20Cimentos%2CS.A.!5e1!3m2!1sen!2sde!4v1684568679899!5m2!1sen!2sde"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(ContactUs);
