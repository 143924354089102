import "./productCenter.scss";
import { BsArrowRight } from "react-icons/bs";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

export default function ProductCenter(props) {
  const { t } = useTranslation();
  const [state, setState] = useState({
    current: props.current,
  });

  const sidebarCardsData = [
    {
      title1: t("product:sidebar.card1.title.1"),
      title2: t("product:sidebar.card1.title.2"),
      url: "/Marine-Cement",
    },
    {
      title1: t("product:sidebar.card2.title.1"),
      title2: t("product:sidebar.card2.title.2"),
      url: "/Oil-Well-Cement",
    },
    {
      title1: t("product:sidebar.card3.title.1"),
      title2: t("product:sidebar.card3.title.2"),
      url: "/Cement-Brick",
    },
    {
      title1: t("product:sidebar.card4.title.1"),
      title2: t("product:sidebar.card4.title.2"),
      url: "/Concrete-Pole",
    },
    {
      title1: t("product:sidebar.card5.title.1"),
      title2: t("product:sidebar.card5.title.2"),
      url: "/Prefabricated-Square-Culvert",
    },
    {
      title1: t("product:sidebar.card6.title.1"),
      title2: t("product:sidebar.card6.title.2"),
      url: "/Reinforced-Concrete-Pipe",
    },
  ];

  const productData = [
    {
      img: "Marine-cement.png",
      title1: t("product:detail.product1.title.1"),
      title2: t("product:detail.product1.title.2"),
      text1: t("product:detail.product1.text.1"),
      title3: t("product:detail.product1.title.3"),
      title4: t("product:detail.product1.title.4"),
      list: [
        {
          title: t("product:detail.product1.title.5"),
          text: t("product:detail.product1.text.2"),
        },
        {
          title: t("product:detail.product1.title.6"),
          text: t("product:detail.product1.text.3"),
        },
        {
          title: t("product:detail.product1.title.7"),
          text: t("product:detail.product1.text.4"),
        },
        {
          title: t("product:detail.product1.title.8"),
          text: t("product:detail.product1.text.5"),
        },
        {
          title: t("product:detail.product1.title.9"),
          text: t("product:detail.product1.text.6"),
        },
      ],
      footerTitle: t("product:detail.product1.title.10"),
      footer: t("product:detail.product1.text.7"),
    },
    {
      img: "Oil-well-cement.png",
      title1: t("product:detail.product2.title.1"),
      title2: t("product:detail.product2.title.2"),
      text1: t("product:detail.product2.text.1"),
      title3: t("product:detail.product2.title.3"),
      title4: t("product:detail.product2.title.4"),
      list: [
        {
          title: t("product:detail.product2.title.5"),
          text: t("product:detail.product2.text.2"),
        },
        {
          title: t("product:detail.product2.title.6"),
          text: t("product:detail.product2.text.3"),
        },
        {
          title: t("product:detail.product2.title.7"),
          text: t("product:detail.product2.text.4"),
        },
        {
          title: t("product:detail.product2.title.8"),
          text: t("product:detail.product2.text.5"),
        },
        {
          title: t("product:detail.product2.title.9"),
          text: t("product:detail.product2.text.6"),
        },
      ],
      footerTitle: t("product:detail.product2.title.10"),
      footer: t("product:detail.product2.text.7"),
    },
    {
      img: "cement-brick.png",
      title1: t("product:detail.product3.title.1"),
      title2: t("product:detail.product3.title.2"),
      text1: t("product:detail.product3.text.1"),
      title3: t("product:detail.product3.title.3"),
      title4: t("product:detail.product3.title.4"),
      list: [
        {
          title: t("product:detail.product3.title.5"),
          text: t("product:detail.product3.text.2"),
        },
        {
          title: t("product:detail.product3.title.6"),
          text: t("product:detail.product3.text.3"),
        },
        {
          title: t("product:detail.product3.title.7"),
          text: t("product:detail.product3.text.4"),
        },
        {
          title: t("product:detail.product3.title.8"),
          text: t("product:detail.product3.text.5"),
        },
        {
          title: t("product:detail.product3.title.9"),
          text: t("product:detail.product3.text.6"),
        },
      ],
      footerTitle: t("product:detail.product3.title.10"),
      footer: t("product:detail.product3.text.7"),
    },
    {
      img: "PoleImg.png",
      title1: t("product:detail.product4.title.1"),
      title2: t("product:detail.product4.title.2"),
      text1: t("product:detail.product4.text.1"),
      title3: t("product:detail.product4.title.3"),
      title4: t("product:detail.product4.title.4"),
      list: [
        {
          title: t("product:detail.product4.title.5"),
          text: t("product:detail.product4.text.2"),
        },
        {
          title: t("product:detail.product4.title.6"),
          text: t("product:detail.product4.text.3"),
        },
        {
          title: t("product:detail.product4.title.7"),
          text: t("product:detail.product4.text.4"),
        },
        {
          title: t("product:detail.product4.title.8"),
          text: t("product:detail.product4.text.5"),
        },
        {
          title: t("product:detail.product4.title.9"),
          text: t("product:detail.product4.text.6"),
        },
      ],
      footerTitle: t("product:detail.product4.title.10"),
      footer: t("product:detail.product4.text.7"),
    },
    {
      img: "prefabricated-square-culvert.png",
      title1: t("product:detail.product5.title.1"),
      title2: t("product:detail.product5.title.2"),
      text1: t("product:detail.product5.text.1"),
      title3: t("product:detail.product5.title.3"),
      title4: t("product:detail.product5.title.4"),
      list: [
        {
          title: t("product:detail.product5.title.5"),
          text: t("product:detail.product5.text.2"),
        },
        {
          title: t("product:detail.product5.title.6"),
          text: t("product:detail.product5.text.3"),
        },
        {
          title: t("product:detail.product5.title.7"),
          text: t("product:detail.product5.text.4"),
        },
        {
          title: t("product:detail.product5.title.8"),
          text: t("product:detail.product5.text.5"),
        },
        {
          title: t("product:detail.product5.title.9"),
          text: t("product:detail.product5.text.6"),
        },
        {
          title: t("product:detail.product5.title.12"),
          text: t("product:detail.product5.text.8"),
        },
        {
          title: t("product:detail.product5.title.13"),
          text: t("product:detail.product5.text.9"),
        },
      ],
      footerTitle: t("product:detail.product5.title.10"),
      footer: t("product:detail.product5.text.7"),
    },
    {
      img: "ConcreteImg.png",
      title1: t("product:detail.product6.title.1"),
      title2: t("product:detail.product6.title.2"),
      text1: t("product:detail.product6.text.1"),
      title3: t("product:detail.product6.title.3"),
      title4: t("product:detail.product6.title.4"),
      list: [
        {
          title: t("product:detail.product6.title.5"),
          text: t("product:detail.product6.text.2"),
        },
        {
          title: t("product:detail.product6.title.6"),
          text: t("product:detail.product6.text.3"),
        },
        {
          title: t("product:detail.product6.title.7"),
          text: t("product:detail.product6.text.4"),
        },
        {
          title: t("product:detail.product6.title.8"),
          text: t("product:detail.product6.text.5"),
        },
        {
          title: t("product:detail.product6.title.9"),
          text: t("product:detail.product6.text.6"),
        },
        {
          title: t("product:detail.product6.title.11"),
          text: t("product:detail.product6.text.8"),
        },
        {
          title: t("product:detail.product6.title.12"),
          text: t("product:detail.product6.text.9"),
        },
        {
          title: t("product:detail.product6.title.13"),
          text: t("product:detail.product6.text.10"),
        },
      ],
      footerTitle: t("product:detail.product6.title.10"),
      footer: t("product:detail.product6.text.7"),
    },
  ];

  const navigate = useNavigate();
  const goTo = (path) => {
    navigate(path);
    // window.scrollTo(0, 0);
  };

  return (
    <div className="container-fluid product-wrapper">
      <div className="container-lg px-3 product">
        <div className="product-sidebar-wrapper">
          <div className="product-sidebar">
            <Fade bottom cascade distance="15%">
              <div className="product-sidebar-title">
                <h2 className="h3">{t("product:sidebar.title.1")}</h2>
                <h4 className="h6">{t("product:sidebar.title.2")}</h4>
              </div>
            </Fade>
            <Fade bottom cascade distance="10%" duration={2000}>
              <div className="product-sidebar-cards">
                {sidebarCardsData.map((data, idx) => {
                  return (
                    <div
                      onClick={() => {
                        goTo(data.url);
                        setState((preState) => {
                          return {
                            current: idx,
                          };
                        });
                      }}
                      className={
                        "product-sidebar-card product-sidebar-card" + (idx + 1)
                      }
                      key={idx}
                    >
                      <div>
                        <h3 className="h5">{data.title1}</h3>
                        <h4 className="h6">{data.title2}</h4>
                      </div>
                      {state.current === idx && (
                        <div className="product-arrow">
                          <BsArrowRight />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </Fade>
          </div>
        </div>
        <div className="product-content-wrapper">
          {productData.map((product, index) => {
            return (
              state.current === index && (
                <div key={index} className="product-content">
                  <div className="product-content-img">
                    <img src={product.img} alt="product-img" />
                  </div>
                  <div className="product-content-text-wrapper">
                    <div className="product-content-title">
                      <h3 className="h4">{product.title1}</h3>
                      <h4 className="h6">{product.title2}</h4>
                    </div>
                    <div className="product-content-body">
                      <h4 className="h5">{product.title3}</h4>
                      <p>{product.text1}</p>

                      <h4 className="h5">{product.title4}</h4>
                      {productData[index].list.map((list, idx) => {
                        return (
                          <div key={idx} className="product-content-list">
                            <h5 className="h6">{list.title}</h5>
                            <p>{list.text}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )
            );
          })}
        </div>
      </div>
      <div className="product-bg"></div>
    </div>
  );
}
