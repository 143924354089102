import "./NewsComp.scss"
import { useState } from "react";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";

export default function PageNumber() {
    const location = useLocation()
    const pages = [
        "/News001",
        "/News002",
        "/News003",
        "/News004"
    ]

    const [state, setState] = useState({
        current: 0
    });

    const navigate = useNavigate();
    
    function next() {
        if (pages.indexOf(location.pathname) < pages.length + 1) {
            navigate(pages[pages.indexOf(location.pathname) + 1])
        }
      }
    
    function prev() {
        if (pages.indexOf(location.pathname) > 0) {
            navigate(pages[pages.indexOf(location.pathname) - 1])
        }

      }

    return (
        <div className="page-number-wrapper">
        <div className="page-number-box" onClick={prev}>
          <BsArrowLeft />
        </div>
          {pages.map((data, idx) => {
            return (
              <div className={(pages.indexOf(location.pathname) + 2 ) < idx || (pages.indexOf(location.pathname) - 2 > idx ) ? "page-number-box-hide": "page-number-box-show"}>
                  <div className={(pages.indexOf(location.pathname) === idx) ? "page-number-box page-number-box-active" : "page-number-box"}
                    onClick={() => {
                        console.log(pages.indexOf(location.pathname))
                        navigate(pages[idx])
                  
                  }}
                    >
                        <span>{idx + 1}</span>
                    </div>
              </div>
              )
            })}
        <div className="page-number-box"  onClick={next}>
          <BsArrowRight />
        </div>
        </div>
    )
}