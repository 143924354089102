import Banner from "../components/common/banner/banner";
import BottomNavbar from './../components/common/bottomNavbar/bottomNavbar';
import { useTranslation } from "react-i18next";
import ProductCenter from './../components/product/productCener';
import { Helmet } from "react-helmet";


export default function ConcretePole() {
     const { t } = useTranslation()
    return (
        <div>
            <Helmet>
                <title>{ t('title.pole')}</title>
            </Helmet>
           <Banner 
           urlBanner="/PoleBg.jpg"
           titleBanner={t('banner.pole')}
            />
            <BottomNavbar
                currentPage={t('navbar.dropdowns.9')}
                mainPage={t('navbar.links.3')}
                mainUrl="/Marine-Cement"
            />
              <ProductCenter
                current={3}
            />
        </div>
    )
}