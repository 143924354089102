import "./hr.scss";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

export default function Hr() {
  const [state, setState] = useState({
    current: 0
  });

  const { t } = useTranslation()
  
  const hrData = [
    {
      img: "hr-img1.png",
      title1: t('hr:talent.content.1.title1'),
      title2: t('hr:talent.content.1.title2'),
      text: t('hr:talent.content.1.text')
    },
    {
      img: "hr-img2.png",
      title1: t('hr:talent.content.2.title1'),
      title2: t('hr:talent.content.2.title2'),
      text: t('hr:talent.content.2.text')
    },
    {
      img: "hr-img3.png",
      title1: t('hr:talent.content.3.title1'),
      title2: t('hr:talent.content.3.title2'),
      text: t('hr:talent.content.3.text')
    },
    {
      img: "hr-img4.png",
      title1: t('hr:talent.content.4.title1'),
      title2: t('hr:talent.content.4.title2'),
      text: t('hr:talent.content.4.text')
    }
  ];

  function next() {
    setState((preState) => {
      if (state.current < (hrData.length - 1)) {
        return {
          current: preState.current + 1
        };
      } else {
        return {
          current: 3
        };
      }
    });
  }

  function prev() {
    setState((preState) => {
      if (state.current > 0) {
        return {
          current: preState.current - 1
        };
      } else {
        return {
          current: 0
        };
      }
    });
  }

  return (
    <div className="container-fluid hr-wrapper">
      <div className="container-lg px-3 hr">
        <Fade bottom distance="15%">
          <div className="hr-sidebar">
            <h2 className="h2">{t('hr:talent.sidebar.title1')}</h2>
            <h3 className="h6">{t('hr:talent.sidebar.title2')}</h3>
          </div>
        </Fade>
        <div className="hr-content-wrapper">
          {hrData.map((data, idx) => {
            return (
              state.current === idx && (
                    <Fade cascade bottom distance="15%">
                      <div className={"hr-content"}>
                          {/* <div className="hr-content-title-wrapper">
                              <h3 className="h3 hr-content-title1">{ data.title1}</h3>
                              <h3 className="h3 hr-content-title2">{data.title2 }</h3>
                          </div> */}
                          <p className="hr-content-text">{data.text }</p>
                                        <div className="hr-content-img">
                      <img src={data.img} alt="hr-img" />
                                        </div>
                                      </div>
                    </Fade>
              )
            );
          })}
          <Fade bottom cascade distance="15%" delay={1500}>
          <div className="hr-page-wrapper">
            <div className="hr-page-box" onClick={prev}>
              <BsArrowLeft />
            </div>
              {hrData.map((data, idx) => {
                return (
                  <div className={(state.current === idx) ? "hr-page-box hr-page-box-active" : "hr-page-box"}
                    onClick={() => {
                      setState((preState) => {
                        console.log(idx);
                        return {
                          current: idx
                        }
                    })
                  }}
                  ><span>{idx + 1}</span></div>
                  )
                })}
            <div className="hr-page-box"  onClick={next}>
              <BsArrowRight />
            </div>
            </div>
            </Fade>
        </div>
      </div>
    </div>
  );
}
