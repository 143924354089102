import "./navbar.scss";
import logo from "../../../images/DugongoLogo2.png";
import { CgMenu, CgClose } from "react-icons/cg";
import { CgChevronDown, CgChevronUp } from "react-icons/cg";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useEffect } from "react";

export default function Navbar() {
  const location = useLocation();

  const { t, i18n } = useTranslation();

  function clickLanguage(lang) {
    i18n.changeLanguage(lang);
  }

  const [state, setState] = useState({
    menuOpen: false,
    langOpen: false,
    aboutOpen: false,
    productOpen: false,
    hrOpen: false,
  });

  function toggleMenu() {
    setState((prevState) => {
      return {
        menuOpen: !prevState.menuOpen,
      };
    });
  }

  function toggleLang() {
    setState((prevState) => {
      return {
        menuOpen: prevState.menuOpen,
        langOpen: !prevState.langOpen,
      };
    });
  }

  function toggleAbout() {
    setState((prevState) => {
      return {
        menuOpen: prevState.menuOpen,
        aboutOpen: !prevState.aboutOpen,
      };
    });
  }

  function toggleProduct() {
    setState((prevState) => {
      return {
        menuOpen: prevState.menuOpen,
        productOpen: !prevState.productOpen,
      };
    });
  }

  function toggleHr() {
    setState((prevState) => {
      return {
        menuOpen: prevState.menuOpen,
        hrOpen: !prevState.hrOpen,
      };
    });
  }

  function hideNavbarLinks() {
    setState((prevState) => {
      return {
        menuOpen: prevState.menuOpen,
        langOpen: false,
        aboutOpen: false,
        productOpen: false,
        hrOpen: false,
      };
    });
  }

  function hideMenu() {
    setState((prevState) => {
      return {
        menuOpen: false,
      };
    });
  }

  function currentPage() {
    if (
      location.pathname == "/Group-Profile" ||
      location.pathname == "/Manatee-Profile" ||
      location.pathname == "/R&D-Center" ||
      location.pathname == "/Corporate-Culture" ||
      location.pathname == "/Organizational-Structure"
    ) {
      return "about";
    }

    if (
      location.pathname == "/Marine-Cement" ||
      location.pathname == "/Oil-Well-Cement" ||
      location.pathname == "/Cement-Brick" ||
      location.pathname == "/Concrete-Pole" ||
      location.pathname == "/Prefabricated-Square-Culvert" ||
      location.pathname == "/Reinforced-Concrete-Pipe"
    ) {
      return "product";
    }

    if (
      location.pathname == "/Talent-Strategy" ||
      location.pathname == "/Career"
    ) {
      return "hr";
    }
  }

  return (
    <div className="container-fluid navbar">
      <div
        className={
          state.menuOpen
            ? "container-lg px-3 navbar-logo-menu--wrapper navbar-bg--blue"
            : "container-lg px-3 navbar-logo-menu--wrapper"
        }
      >
        <div
          className={
            state.menuOpen
              ? "row navbar--menu-logo navbar-menu-logo--mobile"
              : "row navbar--menu-logo"
          }
        >
          <div
            className={
              state.menuOpen
                ? "col-9 col-md-6 col-lg-3 navbar--logo navbar-logo--mobile"
                : "col-9 col-md-6 col-lg-3 navbar--logo"
            }
          >
            <NavLink to="/">
              <img className="navbar-logo--img" src={logo} alt="logo" />
            </NavLink>
          </div>

          <div
            onMouseLeave={hideNavbarLinks}
            className={
              state.menuOpen
                ? "col-1 col-lg-7 navbar--links navbar-links--show"
                : "col-1 col-lg-7 navbar--links"
            }
          >
            <div className="navbar-link">
              <div onClick={hideMenu}>
                <NavLink to="/">{t("navbar.links.1")}</NavLink>
              </div>
              <div className="navbar-link-dropdown--wrapper">
                <div onClick={toggleAbout}>
                  <span
                    style={{
                      color: currentPage() === "about" ? "#ff9901" : "",
                    }}
                  >
                    {t("navbar.links.2")}
                  </span>
                  <span
                    className="navbar-lang--icon"
                    style={{
                      color: currentPage() === "about" ? "#ff9901" : "",
                    }}
                  >
                    {state.aboutOpen ? <CgChevronUp /> : <CgChevronDown />}
                  </span>
                </div>
                <div
                  onClick={hideMenu}
                  className={
                    state.aboutOpen
                      ? "navbar-link--dropdown navbar-link-dropdown--show"
                      : "navbar-link--dropdown"
                  }
                >
                  <div>
                    <NavLink to="/Group-Profile">
                      <div className="dropdown-item">
                        {t("navbar.dropdowns.1")}
                      </div>
                    </NavLink>
                  </div>
                  <div>
                    <NavLink to="/Manatee-Profile">
                      <div className="dropdown-item">
                        {t("navbar.dropdowns.2")}
                      </div>
                    </NavLink>
                  </div>
                  <div>
                    <NavLink to="/R&D-Center">
                      <div className="dropdown-item">
                        {t("navbar.dropdowns.3")}
                      </div>
                    </NavLink>
                  </div>
                  <div>
                    <NavLink to="/Corporate-Culture">
                      <div className="dropdown-item">
                        {t("navbar.dropdowns.4")}
                      </div>
                    </NavLink>
                  </div>
                  <div>
                    <NavLink to="/Quality">
                      <div className="dropdown-item">
                        {t("navbar.dropdowns.14")}
                      </div>
                    </NavLink>
                  </div>
                  <div>
                    <NavLink to="/Organizational-Structure">
                      <div className="dropdown-item">
                        {t("navbar.dropdowns.5")}
                      </div>
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="navbar-link-dropdown--wrapper">
                <div onClick={toggleProduct}>
                  <span
                    style={{
                      color: currentPage() === "product" ? "#ff9901" : "",
                    }}
                  >
                    {t("navbar.links.3")}
                  </span>
                  <span
                    className="navbar-lang--icon"
                    style={{
                      color: currentPage() === "product" ? "#ff9901" : "",
                    }}
                  >
                    {state.productOpen ? <CgChevronUp /> : <CgChevronDown />}
                  </span>
                </div>

                <div
                  onClick={hideMenu}
                  className={
                    state.productOpen
                      ? "navbar-link--dropdown navbar-link-dropdown--show"
                      : "navbar-link--dropdown"
                  }
                >
                  <div>
                    <NavLink to="/Marine-Cement">
                      <div className="dropdown-item">
                        {t("navbar.dropdowns.6")}
                      </div>
                    </NavLink>
                  </div>
                  <div>
                    <NavLink to="/Oil-Well-Cement">
                      <div className="dropdown-item">
                        {t("navbar.dropdowns.7")}
                      </div>
                    </NavLink>
                  </div>
                  <div>
                    <NavLink to="/Cement-Brick">
                      <div className="dropdown-item">
                        {t("navbar.dropdowns.8")}
                      </div>
                    </NavLink>
                  </div>
                  <div>
                    <NavLink to="/Concrete-Pole">
                      <div className="dropdown-item">
                        {t("navbar.dropdowns.9")}
                      </div>
                    </NavLink>
                  </div>
                  <div>
                    <NavLink to="/Prefabricated-Square-Culvert">
                      <div className="dropdown-item">
                        {t("navbar.dropdowns.10")}
                      </div>
                    </NavLink>
                  </div>
                  <div>
                    <NavLink to="/Reinforced-Concrete-Pipe">
                      <div className="dropdown-item">
                        {t("navbar.dropdowns.11")}
                      </div>
                    </NavLink>
                  </div>
                </div>
              </div>
              <div onClick={hideMenu}>
                <NavLink to="/News001">{t("navbar.links.6")}</NavLink>
              </div>
              <div className="navbar-link-dropdown--wrapper">
                <div onClick={toggleHr}>
                  <span
                    style={{ color: currentPage() === "hr" ? "#ff9901" : "" }}
                  >
                    {t("navbar.links.4")}
                  </span>
                  <span
                    className="navbar-lang--icon"
                    style={{ color: currentPage() === "hr" ? "#ff9901" : "" }}
                  >
                    {state.hrOpen ? <CgChevronUp /> : <CgChevronDown />}
                  </span>
                </div>

                <div
                  onClick={hideMenu}
                  className={
                    state.hrOpen
                      ? "navbar-link--dropdown navbar-link-dropdown--show"
                      : "navbar-link--dropdown"
                  }
                >
                  <div>
                    <NavLink to="/Talent-Strategy">
                      {" "}
                      <div className="dropdown-item">
                        {t("navbar.dropdowns.12")}
                      </div>
                    </NavLink>
                  </div>
                  <div>
                    <NavLink to="/Career">
                      <div className="dropdown-item">
                        {t("navbar.dropdowns.13")}
                      </div>
                    </NavLink>
                  </div>
                </div>
              </div>
              <div onClick={hideMenu}>
                <NavLink to="/Contact">{t("navbar.links.5")}</NavLink>
              </div>
            </div>
          </div>

          <div
            onMouseLeave={hideNavbarLinks}
            className={
              state.menuOpen
                ? "col-4 col-sm-2 col-md-1 col-lg-1 navbar-lang--wrapper navbar-lang--mobile"
                : "col-lg-1 navbar-lang--wrapper"
            }
          >
            <div className="navbar--lang" onClick={toggleLang}>
              <span>{t("navbar.lang")}</span>
              <span className="navbar-lang--icon">
                {state.langOpen ? <CgChevronUp /> : <CgChevronDown />}
              </span>
            </div>

            <div
              onClick={hideMenu}
              className={
                state.langOpen
                  ? "navbar-lang--dropdown navbar-link-dropdown--show"
                  : "navbar-lang--dropdown"
              }
            >
              <div
                onClick={() => clickLanguage("cn")}
                style={{
                  color: i18n.resolvedLanguage === "cn" ? "#ff9901" : "",
                }}
              >
                中文
              </div>
              <div
                onClick={() => clickLanguage("pt")}
                style={{
                  color: i18n.resolvedLanguage === "pt" ? "#ff9901" : "",
                }}
              >
                Português
              </div>
            </div>
          </div>

          <div className="col-2 navbar--menu">
            <div className="navbar-menu--icon" onClick={toggleMenu}>
              {state.menuOpen ? (
                <CgClose size={20} color="#ffffff" />
              ) : (
                <CgMenu size={20} color="#194189" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
