import React from "react";
import { MdArrowRightAlt } from "react-icons/md";
import cementMarine from "../../../images/cementMarine.png";
import oilCement from "../../../images/oilCement.png";
import Bricks from "../../../images/Bricks.png";
import PipeImg from "../../../images/PipeImg.png";
import squareCulvert from "../../../images/squareCulvert.png";
import concretePole from "../../../images/concretePole.png";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const HomeProdCardSm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      <div className="home-prod-card-sm">
        <div className="prod-card-container-sm d-flex flex-column gap-1  justify-content-end align-items-center container-fluid px-0">
          <div
            className="prod-cards-promo card-color-1 py-2 gap-1  px-3 d-flex  justify-content-center  "
            style={{ backgroundColor: "#194189" }}
            onClick={() => {
              navigate("/Marine-Cement");
            }}
          >
            <div className="prod-card-wrap">
              <h3 className=" card-title eng mb-1  ">
                {t("home:card.1.title1")}
              </h3>
              <h3 className=" card-title  cn mb-0">
                {t("home:card.1.title2")}
              </h3>
              <MdArrowRightAlt
                className="prod-card-arrow"
                size={24}
                color="white"
              />
            </div>
            <div className="prod-card-hover">
              <img src={cementMarine} alt="" />
            </div>
          </div>
          <div
            className="prod-cards-promo card-color-1 py-2  px-3 gap-1  d-flex   justify-content-center "
            style={{ backgroundColor: "#468C98" }}
            onClick={() => {
              navigate("/Oil-Well-Cement");
            }}
          >
            <div className="prod-card-wrap">
              <h3 className=" card-title eng mb-1 ">
                {t("home:card.2.title1")}
              </h3>
              <h3 className=" card-title  cn mb-0">
                {t("home:card.2.title2")}
              </h3>
              <MdArrowRightAlt
                className="prod-card-arrow"
                size={24}
                color="white"
              />
            </div>
            <div className="prod-card-hover">
              <img src={oilCement} alt="" />
            </div>
          </div>
          <div
            className="prod-cards-promo card-color-1 py-2  px-3 gap-1 d-flex  justify-content-center"
            style={{ backgroundColor: "#B3A394" }}
            onClick={() => {
              navigate("/Cement-Brick");
            }}
          >
            <div className="prod-card-wrap">
              <h3 className=" card-title eng mb-1 ">
                {t("home:card.3.title1")}
              </h3>
              <h3 className=" card-title  cn mb-0">
                {t("home:card.3.title2")}
              </h3>
              <MdArrowRightAlt
                className="prod-card-arrow"
                size={24}
                color="white"
              />
            </div>
            <div className="prod-card-hover">
              <img src={Bricks} alt="" />
            </div>
          </div>
          <div
            className="prod-cards-promo card-color-1 py-2  px-3 gap-1  d-flex  justify-content-center"
            style={{ backgroundColor: "#F7934C" }}
            onClick={() => {
              navigate("Concrete-Pole");
            }}
          >
            <div className="prod-card-wrap">
              <h3 className=" card-title eng mb-1 ">
                {t("home:card.4.title1")}
              </h3>
              <h3 className=" card-title  cn mb-0">
                {t("home:card.4.title2")}
              </h3>
              <MdArrowRightAlt
                className="prod-card-arrow"
                size={24}
                color="white"
              />
            </div>
            <div className="prod-card-hover">
              <img src={concretePole} alt="" />
            </div>
          </div>
          <div
            className="prod-cards-promo card-color-1 py-2 gap-1 px-3  d-flex  justify-content-center"
            style={{ backgroundColor: "#048A81" }}
            onClick={() => {
              navigate("/Prefabricated-Square-Culvert");
            }}
          >
            <div className="prod-card-wrap">
              <h3 className=" card-title eng mb-1 ">
                {t("home:card.5.title1")}
              </h3>
              <h3 className=" card-title  cn mb-0">
                {t("home:card.5.title2")}
              </h3>
              <MdArrowRightAlt
                className="prod-card-arrow"
                size={24}
                color="white"
              />
            </div>
            <div className="prod-card-hover">
              <img src={squareCulvert} alt="" />
            </div>
          </div>
          <div
            className="prod-cards-promo card-color-1 py-2  px-3 gap-1  d-flex  justify-content-center"
            style={{ backgroundColor: "#AEB4A9" }}
            onClick={() => {
              navigate("/Reinforced-Concrete-Pipe");
            }}
          >
            <div className="prod-card-wrap">
              <h3 className=" card-title eng mb-1  ">
                {t("home:card.6.title1")}
              </h3>
              <h3 className=" card-title  cn mb-0">
                {t("home:card.6.title2")}
              </h3>
              <MdArrowRightAlt
                className="prod-card-arrow"
                size={24}
                color="white"
              />
            </div>
            <div className="prod-card-hover">
              <img src={PipeImg} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeProdCardSm;
