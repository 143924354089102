import Banner from "../components/common/banner/banner";
import BottomNavbar from './../components/common/bottomNavbar/bottomNavbar';
import { useTranslation } from "react-i18next";
import ProductCenter from './../components/product/productCener';
import { Helmet } from "react-helmet";

export default function OilWellCement() {
    const { t } = useTranslation()
     
    return (
        <div>
            <Helmet>
                <title>{ t('title.oil')}</title>
            </Helmet>
           <Banner 
           urlBanner="/OilWellBg2.jpg"
           titleBanner={t('banner.oil')}
            />
            <BottomNavbar
                currentPage={t('navbar.dropdowns.7')}
                mainPage={t('navbar.links.3')}
                mainUrl="/Marine-Cement"
            />
            <ProductCenter
                current={1}
            />
        </div>
    )
}