import Banner from "../components/common/banner/banner";
import BottomNavbar from './../components/common/bottomNavbar/bottomNavbar';
import { useTranslation } from "react-i18next";
import ProductCenter from './../components/product/productCener';
import { Helmet } from "react-helmet";

export default function ReinforcedConcretePipe() {
    const { t } = useTranslation()

    return (
        <div>
            <Helmet>
                <title>{t('title.rein')}</title>
            </Helmet>
           <Banner 
           urlBanner="/PipeBgImg.png"
           titleBanner={t('banner.rein')}
            />
            <BottomNavbar
                currentPage={t('navbar.dropdowns.11')}
                mainPage={t('navbar.links.3')}
                mainUrl="/Marine-Cement"
            />
             <ProductCenter
                current={5}
            />
        </div>
    )
}