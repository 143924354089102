import AboutSidebar from "./aboutSidebar";
import AboutBody from "./aboutBody";
import { useTranslation } from "react-i18next";

export default function AboutUs(props) {
    const {t} = useTranslation()
    return (
        <div className="containter-fluid about-us--container">
            <div className="container-lg px-3 about-us">
                <AboutSidebar
                    title={props.title}
                    subTitle={props.subTitle}
                />
                <AboutBody
                    text1={props.text1}
                    text2={props.text2}
                    text3={props.text3}
                    imgUrl={props.imgUrl}
                    imgAlt={props.imgAlt}
                    title2={props.title2}
                />
            </div>
        </div>
    )
}