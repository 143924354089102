import Banner from "../components/common/banner/banner";
import BottomNavbar from './../components/common/bottomNavbar/bottomNavbar';
import { useTranslation } from "react-i18next";
import AboutCulture from "../components/about/aboutCulture";
import { Helmet } from "react-helmet";

export default function CorporateCulture() {
    const { t } = useTranslation()
    return (
        <div>
            <Helmet>
        <title>{t('title.culture')}</title>
      </Helmet>
           <Banner 
           urlBanner="/DugongoAboutBg.jpg"
           titleBanner={t('about:body.4.title')}
            />
               <BottomNavbar
                currentPage={t('navbar.dropdowns.4')}
                mainPage={t('navbar.links.2')}
                mainUrl="/Group-Profile"
            />
            <AboutCulture />
        </div>
    )
}