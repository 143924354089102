import icon1 from "../../images/icon1-active.png";
import { useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from "react-i18next";
import 'swiper/css';


export default function AboutCulture() {
  
  const { t } = useTranslation()
  
  const [state, setState] = useState({
    card1: true,
    card2: false,
    card3: false,
    card4: false
  });

  function showCard1() {
    setState((preState) => {
      return {
        card1: true,
        card2: false,
        card3: false,
        card4: false
      };
    });
  }

  function showCard2() {
    setState((preState) => {
      return {
        card1: false,
        card2: true,
        card3: false,
        card4: false
      };
    });
  }

  function showCard3() {
    setState((preState) => {
      return {
        card1: false,
        card2: false,
        card3: true,
        card4: false
      };
    });
  }

  function showCard4() {
    setState((preState) => {
      return {
        card1: false,
        card2: false,
        card3: false,
        card4: true
      };
    });
  }

  const cardData = [
    {
      icon: "icon1.png",
      activeIcon: "icon1-active.png",
      title1: t('about:culture.card1.title1'),
      title2: t('about:culture.card1.title2'),
      current: 1
    },
    {
      icon: "icon2.png",
      activeIcon: "icon2-active.png",
      title1: t('about:culture.card2.title1'),
      title2: t('about:culture.card2.title2'),
      current: 2
    },
    {
      icon: "icon3.png",
      activeIcon: "icon3-active.png",
      title1: t('about:culture.card3.title1'),
      title2: t('about:culture.card3.title2'),
      current: 3
    },
    {
      icon: "icon4.png",
      activeIcon: "icon4-active.png",
      title1: t('about:culture.card4.title1'),
      title2: t('about:culture.card4.title2'),
      current: 4
    }
  ];
  return (
    <div>
      <div className="container-fluid about-culture-container">
        <div className="container-lg about-culture">
          <div className="about-culture-top">
            <div className="about-culture-top-title">
              <h2 className="h2">{t('about:culture.top.title1')}</h2>
              <h3 className="h6">{t('about:culture.top.title2')}</h3>
            </div>

            <div className="culture-card-container">
              {/* Management Card */}
              <div
                onClick={showCard1}
                className={
                  state.card1
                    ? "culture-card-wrapper card-active"
                    : "culture-card-wrapper"
                }
              >
                <div className="culture-card">
                  {state.card1 ? (
                    <img src={cardData[0].activeIcon} />
                  ) : (
                    <img src={cardData[0].icon} />
                  )}
                  <h3>{cardData[0].title1}</h3>
                  <h4>{cardData[0].title2}</h4>
                </div>
                <div className="culture-icon">
                  {state.card1 && <img src={icon1} alt="triangle" />}
                </div>
              </div>

              {/* Enterprise Spirit Card */}
              <div
                onClick={showCard2}
                className={
                  state.card2
                    ? "culture-card-wrapper card-active"
                    : "culture-card-wrapper"
                }
              >
                <div className="culture-card">
                  {state.card2 ? (
                    <img src={cardData[1].activeIcon} />
                  ) : (
                    <img src={cardData[1].icon} />
                  )}
                  <h3>{cardData[1].title1}</h3>
                  <h4>{cardData[1].title2}</h4>
                </div>
                <div className="culture-icon">
                  {state.card2 && <img src={icon1} alt="triangle" />}
                </div>
              </div>

              {/* Core Values Card */}
              <div
                onClick={showCard3}
                className={
                  state.card3
                    ? "culture-card-wrapper card-active"
                    : "culture-card-wrapper"
                }
              >
                <div className="culture-card">
                  {state.card3 ? (
                    <img src={cardData[2].activeIcon} />
                  ) : (
                    <img src={cardData[2].icon} />
                  )}
                  <h3>{cardData[2].title1}</h3>
                  <h4>{cardData[2].title2}</h4>
                </div>
                <div className="culture-icon">
                  {state.card3 && <img src={icon1} alt="triangle" />}
                </div>
              </div>

              {/* Competitiveness Card */}
              <div
                onClick={showCard4}
                className={
                  state.card4
                    ? "culture-card-wrapper card-active"
                    : "culture-card-wrapper"
                }
              >
                <div className="culture-card">
                  {state.card4 ? (
                    <img src={cardData[3].activeIcon} />
                  ) : (
                    <img src={cardData[3].icon} />
                  )}
                  <h3>{cardData[3].title1}</h3>
                  <h4>{cardData[3].title2}</h4>
                </div>
                <div className="culture-icon">
                  {state.card4 && <img src={icon1} alt="triangle" />}
                </div>
              </div>

            </div>

            <div className="culture-card-swiper">
            <Swiper
      spaceBetween={2}
        slidesPerView={2}
        loop={true}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
    >
           <SwiperSlide><div
                onClick={showCard1}
                className={
                  state.card1
                    ? "culture-card-wrapper card-active"
                    : "culture-card-wrapper"
                }
              >
                <div className="culture-card">
                  {state.card1 ? (
                    <img src={cardData[0].activeIcon} />
                  ) : (
                    <img src={cardData[0].icon} />
                  )}
                  <h3>{cardData[0].title1}</h3>
                  <h4>{cardData[0].title2}</h4>
                </div>
                <div className="culture-icon">
                  {state.card1 && <img src={icon1} alt="triangle" />}
                </div>
              </div></SwiperSlide>

              <SwiperSlide> <div
                onClick={showCard2}
                className={
                  state.card2
                    ? "culture-card-wrapper card-active"
                    : "culture-card-wrapper"
                }
              >
                <div className="culture-card">
                  {state.card2 ? (
                    <img src={cardData[1].activeIcon} />
                  ) : (
                    <img src={cardData[1].icon} />
                  )}
                  <h3>{cardData[1].title1}</h3>
                  <h4>{cardData[1].title2}</h4>
                </div>
                <div className="culture-icon">
                  {state.card2 && <img src={icon1} alt="triangle" />}
                </div>
              </div></SwiperSlide>

              <SwiperSlide> <div
                onClick={showCard3}
                className={
                  state.card3
                    ? "culture-card-wrapper card-active"
                    : "culture-card-wrapper"
                }
              >
                <div className="culture-card">
                  {state.card3 ? (
                    <img src={cardData[2].activeIcon} />
                  ) : (
                    <img src={cardData[2].icon} />
                  )}
                  <h3>{cardData[2].title1}</h3>
                  <h4>{cardData[2].title2}</h4>
                </div>
                <div className="culture-icon">
                  {state.card3 && <img src={icon1} alt="triangle" />}
                </div>
              </div></SwiperSlide>

              <SwiperSlide><div
                onClick={showCard4}
                className={
                  state.card4
                    ? "culture-card-wrapper card-active"
                    : "culture-card-wrapper"
                }
              >
                <div className="culture-card">
                  {state.card4 ? (
                    <img src={cardData[3].activeIcon} />
                  ) : (
                    <img src={cardData[3].icon} />
                  )}
                  <h3>{cardData[3].title1}</h3>
                  <h4>{cardData[3].title2}</h4>
                </div>
                <div className="culture-icon">
                  {state.card4 && <img src={icon1} alt="triangle" />}
                </div>
              </div></SwiperSlide>
</Swiper>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="container-lg culture-content-wrapper">
          {state.card1 && (
            <div className="culture-content">
              <div className="culture-content-left">
                <h4 className="h5">{t('about:culture.card1.title1')}</h4>
                <h3 className="h5">{t('about:culture.card1.title2')}</h3>
              </div>
              <div className="culture-content-right">
                <div className="culture-row">
                  <div>
                    <h4 className="h6">{t('about:culture.card1.content1.title1')}</h4>
                    <h3 className="h6">{t('about:culture.card1.content1.title2')}</h3>
                  </div>
                  <div>
                    <h4 className="h6">{t('about:culture.card1.content2.title1')}</h4>
                    <h3 className="h6">{t('about:culture.card1.content2.title2')}</h3>
                  </div>
                </div>
                <div className="culture-row">
                  <div>
                    <h4 className="h6">{t('about:culture.card1.content3.title1')}</h4>
                    <h3 className="h6">{t('about:culture.card1.content3.title2')}</h3>
                  </div>
                  <div>
                    <h4 className="h6">{t('about:culture.card1.content4.title1')}</h4>
                    <h3 className="h6">{t('about:culture.card1.content4.title2')}</h3>
                  </div>
                </div>
              </div>
            </div>
          )}

          {state.card2 && (
              <div className="culture-content">
              <div className="culture-content-left">
                <h4 className="h5">{t('about:culture.card2.title1')}</h4>
                <h3 className="h5">{t('about:culture.card2.title2')}</h3>
              </div>
              <div className="culture-content-right">
                <div className="culture-row">
                  <div>
                  <h4 className="h6">{t('about:culture.card2.content1.title1')}</h4>
                    <h3 className="h6">{t('about:culture.card2.content1.title2')}</h3>
                  </div>
                  <div>
                   <h4 className="h6">{t('about:culture.card2.content3.title1')}</h4>
                    <h3 className="h6">{t('about:culture.card2.content3.title2')}</h3>
                  </div>
                </div>
                <div className="culture-row">
                <div>
                     <h4 className="h6">{t('about:culture.card2.content4.title1')}</h4>
                     <h3 className="h6">{t('about:culture.card2.content4.title2')}</h3>
                   </div>
                   <div>
                    <h4 className="h6"> {t('about:culture.card2.content5.title1')}</h4>
                     <h3 className="h6">{t('about:culture.card2.content5.title2')}</h3>
                   </div>
                </div>
              </div>
            </div>
          )}
          {state.card3 && (
            <div className="culture-content">
              <div className="culture-content-left">
                <h4 className="h5">{t('about:culture.card3.title1')}</h4>
                <h3 className="h5">{t('about:culture.card3.title2')}</h3>
              </div>
              <div className="culture-content-right">
                <div className="culture-row">
                  <div>
                    <h4 className="h6">{t('about:culture.card3.content1.title1')} </h4>
                    <h3 className="h6">{t('about:culture.card3.content1.title2')} </h3>
                  </div>
                  <div>
                    <h4 className="h6">{t('about:culture.card3.content2.title1')} </h4>
                    <h3 className="h6">{t('about:culture.card3.content2.title2')}</h3>
                  </div>
                </div>
                <div className="culture-row">
                  <div>
                    <h4 className="h6">{t('about:culture.card3.content3.title1')}</h4>
                    <h3 className="h6">{t('about:culture.card3.content3.title2')}</h3>
                  </div>
                  <div>
                    <h4 className="h6">{t('about:culture.card3.content4.title1')}</h4>
                    <h3 className="h6">{t('about:culture.card3.content4.title2')}</h3>
                  </div>
                </div>
              </div>
            </div>
          )}

          {state.card4 && (
            <div className="culture-content">
              <div className="culture-content-left">
                <h4 className="h5">{t('about:culture.card4.title1')}</h4>
                <h3 className="h5">{t('about:culture.card4.title2')}</h3>
              </div>
              <div className="culture-content-right">
                <div className="culture-row">
                  <div>
                    <h4 className="h6">{t('about:culture.card4.content1.title1')} </h4>
                    <h3 className="h6">{t('about:culture.card4.content1.title2')} </h3>
                  </div>
                  <div>
                    <h4 className="h6">{t('about:culture.card4.content2.title1')}</h4>
                    <h3 className="h6">{t('about:culture.card4.content2.title2')} </h3>
                  </div>
                </div>
                <div className="culture-row">
                  <div>
                    <h4 className="h6">{t('about:culture.card4.content3.title1')}</h4>
                    <h3 className="h6">{t('about:culture.card4.content3.title2')}</h3>
                  </div>
                  <div>
                    <h4 className="h6">{t('about:culture.card4.content4.title1')}</h4>
                    <h3 className="h6">{t('about:culture.card4.content4.title2')}</h3>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
