import "./hr.scss";
import { useTranslation } from "react-i18next";

export default function CareerComp() {
  const { t } = useTranslation();
  return (
    <div className="container-fluid hr-wrapper">
      <div className="container-lg px-3 hr">
        <div className="hr-sidebar">
          <h2 className="h2">{t("hr:career.sidebar.title1")}</h2>
          <h3 className="h6">{t("hr:career.sidebar.title2")}</h3>
        </div>
        <div className="hr-content-wrapper">
          <div className="hr-content">
            <div className="hr-content-title-wrapper">
              <h3 className="h3 hr-content-title1">
                
              </h3>
              <h3 className="h3 hr-content-title2"></h3>
              
            </div>
            <p className="hr-content-text">{t("hr:career.content.title1")}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
