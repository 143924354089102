
import "../components/common/navbar/navbar.scss"
//import { SliderData } from "../components/TestSlider/SliderData"
import BannerHome from "../components/home/BannerHome/BannerHome"
import HomeProdCardMd from "../components/home/BannerHome/HomeProdCardMd"
import HomeProdCardSm from "../components/home/BannerHome/HomeProdCardSm"
import HomeAbout from "../components/home/homeAbout/homeAbout"
import { Helmet } from "react-helmet";

export default function Home() {
    return (
        <div >
          <Helmet>
          <title>Início - Moçambique Dugongo Cimentos Co, SA</title>
      </Helmet>
          <BannerHome/>
          <HomeProdCardMd/>
          {/* <HomeProdCardSm/> */}
          <HomeAbout/>
        </div>
    )
}