import React from "react";
import { MdArrowRightAlt } from "react-icons/md";
import cementMarine from "../../../images/cementMarine.png";
import oilCement from "../../../images/oilCement.png";
import Bricks from "../../../images/Bricks.png";
import PipeImg from "../../../images/PipeImg.png";
import squareCulvert from "../../../images/squareCulvert.png";
import concretePole from "../../../images/concretePole.png";
import { NavLink } from "react-router-dom";
import "./BannerHome.scss";
import { useTranslation } from "react-i18next";

const HomeProdCardMd = () => {
  const {t} = useTranslation()
  return (
    <div className="home-prod-card-md">
      <div className="prod-card-container-md  d-flex justify-content-between align-items-end container-fluid px-0">
        <div
          className="prod-cards-promo card-color-1 p-2  d-flex flex-column justify-content-center "
          style={{ backgroundColor: "#194189" }}
        >
          <NavLink to="/Marine-Cement">
            <div>
              <div
                className="prod-card-hover2"
                style={{ backgroundColor: "#194189" }}
              >
                <div className="prod-card-hover">
                  <img src={cementMarine} alt="" />
                </div>
              </div>
              <div className="">
                <h3 className=" card-title eng mb-2  ">{t('home:card.1.title1')}</h3>
                <h3 className=" card-title  cn mb-0">{t('home:card.1.title2')}</h3>
                <MdArrowRightAlt
                  className="prod-card-arrow"
                  size={24}
                  color="white"
                />
              </div>
            </div>
          </NavLink>
          
        </div>
        <div
          className="prod-cards-promo card-color-1 p-2   d-flex flex-column  justify-content-center "
          style={{ backgroundColor: "#468C98" }}
        >
          <NavLink to="/Oil-Well-Cement">
            <div>
              <div className="prod-card-hover">
                <img src={oilCement} alt="" />
              </div>
              <div>
                <h3 className=" card-title eng mb-2 ">{t('home:card.2.title1')}</h3>
                <h3 className=" card-title  cn mb-0">{t('home:card.2.title2')}</h3>
                <MdArrowRightAlt
                  className="prod-card-arrow"
                  size={24}
                  color="white"
                />
              </div>
            </div>
          </NavLink>
        </div>
        <div
          className="prod-cards-promo card-color-1 p-2  d-flex flex-column  justify-content-center"
          style={{ backgroundColor: "#B3A394" }}
        >
          <NavLink  to="/Cement-Brick" >
            <div>
              <div className="prod-card-hover">
                <img src={Bricks} alt="" />
              </div>
              <div>
                <h3 className=" card-title eng mb-2 ">{t('home:card.3.title1')}</h3>
                <h3 className=" card-title  cn mb-0">{t('home:card.3.title2')}</h3>
                <MdArrowRightAlt
                  className="prod-card-arrow"
                  size={24}
                  color="white"
                />
              </div>
            </div>
          </NavLink>
        </div>
        <div
          className="prod-cards-promo card-color-1 p-2  d-flex flex-column  justify-content-center"
          style={{ backgroundColor: "#F7934C" }}
        >
          <NavLink to="/Concrete-Pole" >
            <div>
              <div className="prod-card-hover">
                <img src={concretePole} alt="" />
              </div>
              <div>
                <h3 className=" card-title eng mb-2 ">{t('home:card.4.title1')}</h3>
                <h3 className=" card-title  cn mb-0">{t('home:card.4.title2')}</h3>
                <MdArrowRightAlt
                  className="prod-card-arrow"
                  size={24}
                  color="white"
                />
              </div>
            </div>
          </NavLink>
        </div>
        <div
          className="prod-cards-promo card-color-1 p-2  d-flex flex-column  justify-content-center"
          style={{ backgroundColor: "#048A81" }}
        >
          <NavLink  to="/Prefabricated-Square-Culvert">
            <div>
              <div className="prod-card-hover">
                <img src={squareCulvert} alt="" />
              </div>
              <div>
                <h3 className=" card-title eng mb-2 ">{t('home:card.5.title1')}</h3>
                <h3 className=" card-title  cn mb-0">{t('home:card.5.title2')}</h3>
                <MdArrowRightAlt
                  className="prod-card-arrow"
                  size={24}
                  color="white"
                />
              </div>
            </div>
          </NavLink>
        </div>
        <div
          className="prod-cards-promo card-color-1 p-2 me-0 d-flex flex-column  justify-content-center"
          style={{ backgroundColor: "#AEB4A9" }}
        >
          <NavLink to="/Reinforced-Concrete-Pipe">
            <div className="prod-card-hover">
              <img src={PipeImg} alt="" />
            </div>
            <div>
              <h3 className=" card-title eng mb-2  ">{t('home:card.6.title1')}</h3>
              <h3 className=" card-title  cn mb-0">{t('home:card.6.title2')}</h3>
              <MdArrowRightAlt
                className="prod-card-arrow"
                size={24}
                color="white"
              />
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default HomeProdCardMd;
