import Banner from "../components/common/banner/banner";
import BottomNavbar from './../components/common/bottomNavbar/bottomNavbar';
import { useTranslation } from "react-i18next";
import ProductCenter from './../components/product/productCener';
import { Helmet } from "react-helmet";

export default function MarineCement() {
    const { t } = useTranslation()
 
    return (
        <div>
            <Helmet>
                <title>{t('title.marine')}</title>
            </Helmet>
           <Banner 
           urlBanner="/MarineBg.jpg"
           titleBanner={t('banner.marine')}
            />
              <BottomNavbar
                currentPage={t('navbar.dropdowns.6')}
                mainPage={t('navbar.links.3')}
                mainUrl="/Marine-Cement"
            />
            <ProductCenter
                current={0}
            />
        </div>
    )
}