import Banner from "../components/common/banner/banner";
import BottomNavbar from "../components/common/bottomNavbar/bottomNavbar";
import { useTranslation } from "react-i18next";
import AboutUs from "../components/about/aboutus";
import { Helmet } from "react-helmet";

export default function GroupProfile() {
  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <title>{ t('title.group')}</title>
      </Helmet>
      <Banner
        urlBanner="/DugongoAboutBg.jpg"
        titleBanner={t("about:body.1.title")}
      />
      <BottomNavbar
        currentPage={t("navbar.dropdowns.1")}
        mainPage={t("navbar.links.2")}
        mainUrl="/Group-Profile"
      />
      <AboutUs
        title={t("about:sidebar.about.sub1.1")}
        title2={t("about:body.1.title")}
        subTitle={t("about:sidebar.about.sub1.2")}
        text1={t("about:groupProfile.text1")}
        text2={t("about:groupProfile.text2")}
        text3={t("about:groupProfile.text3")}
        imgUrl="group-overview.png"
        imgAlt="group overview"
      />
    </div>
  );
}
