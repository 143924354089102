import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import common from "./locales/cn/common.json"
import home from "./locales/cn/home.json"
import about from "./locales/cn/about.json"
import product from "./locales/cn/product.json"
import contact from "./locales/cn/contact.json"
import hr from "./locales/cn/hr.json"
import news from "./locales/cn/news.json"
import commonPT from "./locales/pt/common.json"
import homePT from "./locales/pt/home.json"
import aboutPT from "./locales/pt/about.json"
import productPT from "./locales/pt/product.json"
import contactPT from "./locales/pt/contact.json"
import hrPT from "./locales/pt/hr.json"
import newsPT from "./locales/pt/news.json"

const languages = ['cn', 'pt'];

i18n
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'pt',
    whitelist: languages,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    partialBundledLanguages: true,
    ns: ['common'],
    resources: {
      'cn': {
        common,
        home,
        about,
        product,
        contact,
        hr,
        news
     },
      'pt': {
        common: commonPT,
        home: homePT,
        about: aboutPT,
        product: productPT,
        contact: contactPT,
        hr: hrPT,
        news: newsPT
      },
    }

  
   
  });

 

export default i18n;